<template>
    <router-link
        :to="{
            name: 'purchaseDetail',
            query: {
                id: info.id
            }
        }"
        class="manage"
    >
        <el-image :src="getImg(info.img)" fit="fit"></el-image>
        <div class="content">
            <div class="name">{{ info.name }}</div>
            <div class="num">预算：{{ info.expectedPrice }}(万元)</div>

            <div class="go">地址：{{ info.address }}</div>
            <div class="btn">
                <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small" plain>
                    <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                    咨询客服
                </el-button>
                <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                    <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                </el-button>
            </div>
            <div class="bottom">截止日期：{{ formatTime(info.registerDeadline, 'YYYY.MM.DD') }}</div>
        </div>
        <div class="look">
            <i class="iconfont iconfont-icon-lliulan"></i>
            <span>{{ info.view || 0 }}个人看过</span>
        </div>
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
.manage {
    width: 226px;
    min-height: 400px;
    background: #ffffff;
    border: 1px solid rgba(56, 85, 142, 0.04);
    .flex-col();
    align-items: stretch;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    margin: 10px 10px 10px 19px;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(255, 135, 0, 0.2);
        border: 1px solid #ff8700;
    }
}

.el-image {
    width: 223px;
    height: 200px;
    flex-shrink: 0;
}
.content {
    .flex-col();
    margin-left: 10px;
    padding: 14px 0 5px;
}

.tags {
    max-width: 100%;
    flex-wrap: nowrap;
    .ellipsis();
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    margin-top: 8px;
    color: @prim;
}
.look {
    padding-bottom: 6px;
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
        margin-left: 10px;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin-left: 5px;
    }
    // position: absolute;
    // right: 20px;
    // bottom: 20px;
}
.name {
    font-size: 16px;
    font-weight: bold;
    color: #292c33;
    height: 26px;
    line-height: 26px;
    overflow: hidden;
    .ellipsis();
}
.btn {
    .flex();
    .icon-button {
        line-height: 26px;
        width: 80px;
        display: flex;
        padding-left: 0;
    }
}
.go {
    font-size: 14px;
    color: #878d99;
    line-height: 22px;
    .ellipsis();
    margin-bottom: 10px;
}
.num {
    font-size: 14px;
    line-height: 22px;
    color: #878d99;
}
.bottom {
    font-size: 13px;
    color: #818999;
    line-height: 18px;
    padding-top: 8px;
    border-top: 1px solid @bg;
    margin-top: 12px;
    align-self: stretch;
}
</style>
